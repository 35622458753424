import { useContext } from "react";
import { SettingsProviderContext } from "./SettingsProvider";
import { SolanaProviderContext } from "./SolanaProvider";
import { format } from "timeago.js";
import { toast } from "react-toastify";
import { short, uiTokenStatus } from "./utils";
import { DataProviderContext } from "./DataProvider";
import { FiEye, FiInfo } from "react-icons/fi";
import { FaCross, FaCrosshairs, FaLink, FaQuestion, FaStar, FaTelegram, FaTwitter } from "react-icons/fa";
import { LuStarOff } from "react-icons/lu";
import NumericLabel from 'react-pretty-numbers'
import './App.css'
import { ModalContextProvider } from "./ModalProvider";
import { IoIosArrowDown, IoIosArrowUp, IoIosWarning } from "react-icons/io";
import { BrowserView, isBrowser, isMobile } from "react-device-detect";
import { UNKNWON_RISK_MASK, decodeMask } from "./riskmask";
import { GrStatusUnknown } from "react-icons/gr";
import { Marks } from "./components";


export function Table({ tokens, className, sortTokens, sortSettings, style }) {
  
  const { account, buy } = useContext(SolanaProviderContext);
  const { settings, watchAddress, stopWatchAddress, toggleShowRadium } = useContext(SettingsProviderContext)
  const { watchedTokens, radiumLast24 } = useContext(DataProviderContext)
  const { info, marksInfo, riskWarningInfo } = useContext(ModalContextProvider)

  const logged = account !== ''

  let merged = [...watchedTokens]
  merged.forEach(v => { v.watched = true })
  tokens.forEach(v => {
    if (watchedTokens.some(m => v.mint === m.mint))
      return
    
    merged.push({
      ...v,
      watched: false
    })
  })
  tokens.sort((a, b) => (Number(a.watched) - Number(b.watched)))
  merged = merged.slice(0, Math.max(10, tokens.length))
  
  const onBuy = ({ mint, bondingCurve, curVolumeSol }) => {
    if (!logged) {
      toast.info('Connect to Phantom wallet, please')
      return
    }

    toast.promise(
      buy(mint, bondingCurve, curVolumeSol, settings),
      {
        pending: 'Signing transaction...',
        success: {
          render({ data }){
            return <a target='_blank' href={`https://solscan.io/tx/${data}`}>txid: {short(data)}</a>
          }
        },
        error: {
          render({ data }){
            console.log(data)
            return 'Error signing'
          }
        }
      }
    )
  }

  function renderSortItem(sortSettings, orderBy) {
    let name = orderBy

    switch (orderBy) {
      case 'sol_reserved':
        name = 'Reserve'
        break
      
      case 'created':
        name = 'Created'
        break

      case 'sol_start':
        name = 'Prebuy'
        break

      case 'updated':
        name = 'Updated'
        break

      case 'radium':
        name = 'Raydium'
        break
    }
    return (
      <a 
        className="text-right"
        href='#' 
        style={{ textDecoration: 'none', position: 'relative' }} 
        onClick={() => sortTokens(orderBy, sortSettings.order.toLowerCase() === 'desc'? 'asc' : 'desc')}
      >
        <div style={{ position: 'absolute', right: '-20px', top: '2px' }}>
        {
          sortSettings.orderBy === orderBy? 
            (sortSettings.order === 'desc'? <IoIosArrowDown style={{ display: 'block'}} /> : <IoIosArrowUp />) : 
            <div ></div>
        }
        </div>
        {name}
      </a>
    )
  }

  const getFadeMode = (status) => {
    switch (status) {
      case uiTokenStatus.down:
        return 'red'
      case uiTokenStatus.up:
        return 'green'
      case uiTokenStatus.new:
        return ''
      default:
        return ''
    }
  }

  const renderAllTokensInfo = () => {
    return (
      <div >
        This token gives you an overview of all the new tokens. It's highly configurable
        <br/>
        <br/>
        To filter tokens click on one of the columns: <b>Updated</b>, <b>Created</b>, <b>Reserve</b>, <b>Prebuy</b>,
        <br/>
        <br/>
        
      </div>
    )
  }

  const renderRow = ({ curVolumeSol, startVolumeSol, mint, riskmask_dynamic, twitter, telegram, website, timestampAdded, symbol:token_name, riskmask, bondingCurve, logo, watched, updated, radium, status, riskmask_data }, i) => {
    let showWarning = false
    let risks = []
    if (riskmask > 0) {
      const res = decodeMask(riskmask, riskmask_data)
      risks = res.risks
      showWarning = res.showWarning
    }

    
    const radiumProgress = ( radium? 100 : Math.min(((parseInt(curVolumeSol)) / (85 * 1e7)), 100))
    return (
      <tr className={`${watched && 'watched'}`} style={{ position: 'relative' }}  key={mint}>
        <td style={{ textAlign: 'left', paddingLeft: '0px', width: isMobile? '80px' : '' }}>
          
          <div className="d-flex align-content-center align-items-center" style={{ width: isMobile? '90px' : '120px'}}>
            <img 
              className="logo" 
              src={logo? logo : "/question.jpeg" } 
              loading="lazy" 
              width="20" 
              height="20" 
              style={{ marginRight: '5px', borderRadius: '50%'}} 
            />
            <a target='_blank' style={{ textDecoration: 'none' }} href={`https://pump.fun/${mint}`}>
              {token_name.length > 10? `${token_name.slice(0, 10)}...` : token_name}
            </a>
          </div>
        </td>
        <td style={{ width: isMobile? '75px' : '80px' }} >
          <div  className="d-flex justify-content-start gap-1 align-items-center">
          { website && <FaLink className="sociallink" onClick={() => window.open(website, '_blank')}/>}
          { twitter && <FaTwitter className="sociallink" onClick={() => window.open(twitter, '_blank')}/>}
          { telegram && <FaTelegram className="sociallink" onClick={() => window.open(telegram, '_blank')}/>}
          { watched && <FaStar color="#45609f" /> }
          </div>
        </td>
        <td style={{ width: '50px'}}>
          {<Marks mask={riskmask_dynamic}></Marks>}
        </td>
        <td style={{ textAlign: 'center', width: isMobile? '70px' : '90px' }}>
          <div>
            {format(updated)}
          </div>
        </td>
        {
          isBrowser && 
          <td style={{ textAlign: 'center', width: '90px' }}>
            
              {format(timestampAdded)}
           
          </td>
        }
        <td className={`fade-it ${getFadeMode(status)}`} data={curVolumeSol} style={{ textAlign: 'center', width: isMobile? '80px' : '80px' }}>
          <NumericLabel>
            {(curVolumeSol / 1e9).toFixed(2)}
          </NumericLabel>

        </td>
        {
          isBrowser && 
          <td style={{ textAlign: 'center', width: '90px' }}>
            <td style={{ textAlign: 'center', width: '80px'}}>
            <NumericLabel>
              {(startVolumeSol / 1e9).toFixed(2)}
            </NumericLabel>
            
          </td>
          </td>
        }
        <td style={{ width: isMobile? '80px' : '130px' }}>
          <div className="d-flex gap-2 p-1" style={{ height: '23px'}}>
            <button 
              className='buyBtn' 
              style={{ width: '30px' }} 
              onClick={() => watched? stopWatchAddress(mint) : watchAddress(mint)}
            >
              {watched? <LuStarOff /> : (isMobile? '⭐' : <FaStar />)}
            </button>
            {
              !radium && 
              <button 
                st
                className={`buyBtn`} 
                onClick={() => isMobile? toast.info('Go to desktop version please') : onBuy({ mint, bondingCurve, curVolumeSol })}
              >
                BUY
              </button> 
            }
            
            {
              
              <button 
                className={`buyBtn`} 
                style={{ visibility: showWarning? 'visible' : 'hidden', width: '30px' }} 
                onClick={() => riskWarningInfo(risks, riskmask)}
              >
                {riskmask & UNKNWON_RISK_MASK? (isMobile? '❓' : <GrStatusUnknown size={16} color="white" />) : (isMobile? '⚠️' : <IoIosWarning size={16} color="#f7f797" />)}
                
              </button> 
            }
          </div>
          
        </td>
        <td style={{ paddingRight: '0px', width: isMobile? '80px' : '100px', textAlign: 'right' }}>
        {isBrowser &&  <div className={`progress`} style={{ left: `${radiumProgress - 100}%` }}></div> }
          <div >{radium? `${format(radium)} 🏆` : `${radiumProgress.toFixed(2)}%`}</div>
        </td>
      </tr>
    )
  }
  
  return (
    <div className={`container ${className}`} style={{ ...style, borderTopLeftRadius: isMobile? '15px' : '0px', borderTopRightRadius: isMobile? '15px' : '0px' }}>
      <div className="container-label">
        <div>All tokens</div>
        <FiInfo className="sociallink" size={20} onClick={() => info(renderAllTokensInfo)} />
      </div>

      {
        radiumLast24 !== null &&
        <div className="container-label" style={{ position: 'absolute', left: 'unset', right: '-3px', top: -25 }}>
          <b>{radiumLast24}</b> Raydiums [24 h]
        </div>
      }
      
      {
        <div className="container-label" style={{ position: 'absolute', left: 'calc(50% - 90px)', top: -25 }}>
          <input checked={settings.showRadium} type="checkbox" onClick={toggleShowRadium} /> Show raydium
        </div>
      }
      <div style={{ padding: '15px 0px 35px 0px', overflow: 'hidden' }}>
        <table className="table" style={{ width: '100%', overflow: 'visible' }}> 
          <thead >
            <tr >
              <th scope="col"></th>
              <th scope="col" ></th>
              <th scope="col"><a href='#' style={{ textDecoration: 'none', position: 'relative' }} onClick={marksInfo}>Marks</a></th>
              <th scope="col" >{renderSortItem(sortSettings, 'updated')}</th>
              {
                isBrowser && 
                <th scope="col" style={{ display: isMobile? 'none' : ''}}>{renderSortItem(sortSettings, 'created')}</th>
              }
              
              <th scope="col">{renderSortItem(sortSettings, 'sol_reserved')}</th>
              {
                isBrowser && 
                <th scope="col" style={{ display: isMobile? 'none' : ''}} >{renderSortItem(sortSettings, 'sol_start')}</th>
              }
              
              <th scope="col"></th>
              <th scope="col text-end">{settings.showRadium? renderSortItem(sortSettings, 'radium') : 'Radium'}</th>
            </tr>
          </thead>
          <tbody className="table-body" style={{}}>
            {merged.map(renderRow)}
          </tbody>
        </table>
      </div>
    </div>)
}