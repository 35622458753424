import { useContext } from 'react'
import { SolanaProviderContext } from './SolanaProvider';
import { DataProviderContext } from './DataProvider';
import { Table } from './Table';
import { ControlPanel } from './ControlPanel';
import { BoughtTokens } from './BoughtTokens';
import { short } from './utils';
import { ModalContextProvider } from './ModalProvider';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import NumericLabel from 'react-pretty-numbers'
import { SolanaIcon } from './media/SolanaIcon';
import { FaTelegram } from 'react-icons/fa';

const FAQ = [
  {
    question: 'What this project is about?',
    response: <>
      It is a <a target='_blank' href='https://pump.fun/'>pumpfun</a> token analyzor that helps you to avoid scammers. 
      It incorparates feauters like: 
      <br/> 
      <br/><b>🤔 Real-time analysis for potential fraud activity</b>
      <br/><b>🚀 Faster transactions</b>
      <br/><b>💅 Better UI</b>
      <br/><b>🤖 Trading bot (coming soon)</b>
      <br/><b>👨‍💻 Constant developing and upgrading</b><br/>
    </>
  },
  {
    question: 'How do you unlock solana?',
    response: <>
      Not many people know, but when buying a token you deposit SOL in exchange of a "space" for your tokens on the blockchain. After selling all the tokens, you don't need the "room" anymore, so you can claim your deposit back. 
      You will be notified in case case you have some solana to unlock. 
      For more info, please, go to official <a target='_blank' href='https://solana.com/developers/cookbook/accounts/close-account'>documentation</a>
    </>
  },
  {
    question: 'How does the analysis works?',
    response: <>
      Our servers are constantly monitoring blockchain for potential fraud activity by analysing certain metrics.
      Some metrics are <b>static</b> meaning they don't change troughout the life of a token. 
      
      <br/><br/>
      For example, <i>Creator has a new account</i>. 
      <br/><br/>
      
      Other metrics are <b>dynamic</b> in a sense they change during a trading process. 
      <br/><br/>
      For example, <i>Developer sold all his tokens</i>.
      <br/><br/>

      <b>Static</b> metrics can be accessed at creation time of a token. Suspicious tokens are reflected with a ⚠️ sign, next to the 'BUY' button in 'All tokens' section. By clicking on it you will be provided a detailed information.
      
      <br/><br/><b>Dynamic</b> metrics pop up during a trading process.
    </>
  },
  {
    question: 'What type of activity is considered suspicious?',
    response: <>
      There are many metrics and they are constantly evolving.
      For example, a token is marked suspicous if its creator was linked to another token that had been "rugpulled" before
    </>
  },
  {
    question: 'How to monitor a specific token?',
    response: <>
      Click on a button with a ⭐️ next to 'Buy' in 'All tokens' section. The token will stick to the top of the table and not going to move anywhere
      
    </>
  },
  
  {
    question: 'Is it safe to use?',
    response: <>
      Yes, when it comes to transaction signing we are just a convinient wrapper around 
      pumpfun smart contract. 
    </>
  }
]
function App() {
  const { account } = useContext(SolanaProviderContext)
  const { tokens, ownedTokens, sortTokens, balanceSol, sortSettings} = useContext(DataProviderContext)
  const { info } = useContext(ModalContextProvider)
  const logged = account !== ''

  const renderWhyUs = () => {
    return (
      <div className='p-3 pt-0 text-start'>
        <div className='mt-3'><b>🚀 Faster transactions</b><br/>we make sure that your signed transactions are sent to as many validators as possible which means faster confirmation time (<b>JITO transactions</b> are coming soon)</div>
        <div className='mt-3'><b>🤔 Analysis</b><br/>tired of scammers? we provide your with a thorough analysis of new tokens to detect potential scam. this analysis is constantly updated as we use it for our own trades</div>
        <div className='mt-3'><b>💅 Better UI</b><br/>everything is in one place. no need to switch back and worth to buy a token and waste a valuable time</div>
        <div className='mt-3'><b>🔓 Unlock solana</b><br/>every time when buying a token you are charged an additional deposit of SOL to "rent a room" for your tokens on the blockchain, after selling all the tokens, you don't need "the room" anymore, so you can claim your deposit back. In case you have some solana unlocked, we will let you know and provide a way tp redeem it</div>
        <div className='mt-3'><b>🤖 Trading bot</b><br/>do you know a profitable strategy? set up your own trading bot based on our analysis and monitor it in real-time (coming soon!)</div>
      </div>
    )
  }

  const renderFaq = () => {
    return (
      <div className='p-3 pt-0 text-start accordion'>
          {
            FAQ.map(({question, response}, i) => (
              <div>
                <input type="checkbox" id={`tab${i}`}/>
                <label class="accordion-label" for={`tab${i}`}><b>{question}</b></label>
                <div class="accordion-content">
                  {response}
                </div>
              </div>
            ))
          }
      </div>
    )
  }

  return (
    <div >
      
      <main >
        <header>
          <nav>
              <a href="#" onClick={() => info(renderFaq, 'FAQ')}>FAQ</a>
              <h1>BUY WIF FUN</h1>
              <a href="#" onClick={() => info(renderWhyUs, 'Why us?')}>Why us?</a>
          </nav>
        </header>
        <div style={{ marginTop: '4vh', marginBottom: '10vh'}}>
             
          <div className='d-flex-column justify-content-center align-items-center h-100 w-100 '>
          <div className='mb-4'>
          
          </div>

            <div>
              {
                account !== '' && 
                <div className='text-center d-flex aign-items-center justify-content-center gap-2'>
                  <div>{short(account, 5)}</div>
                  <div>{balanceSol && <><b><NumericLabel>{(balanceSol / 1e9).toFixed(2)}</NumericLabel></b> SOL</>} </div>
                  
                </div>
              }
            </div>
           
            

            <BrowserView className='w-100'>
              <ControlPanel 
                disabled
                className="w-100"
              />  
            </BrowserView>
            
            <MobileView className='p-4'>
              <div className='text-center font-lg' style={{ fontSize: '1.3rem'}}>
                Mobile version is being developed. Please, use a desktop version instead. Sorry for the inconvinience  
              </div>
            </MobileView>

               
            {
              isMobile &&
              <div className='w-100 d-flex align-items-center justify-content-center'>
              <FaTelegram size={40} className="sociallink" onClick={() => window.open('https://t.me/buywiffun', '_blank')}/>
              </div>
            }

            <BrowserView className='w-100'>
            {
              logged && 
              <BoughtTokens 
                ownedTokens={ownedTokens} 
                className="w-100 pt-0 pb-0 mt-5 mb-1"
                style={{ overflow: 'visible' }}
              />
            }
            </BrowserView>
            
              <Table
                className="w-100 pt-0 pb-0 mt-5"
                style={{ minHeight: '500px' }} 
                tokens={tokens} 
                sortTokens={sortTokens}
                sortSettings={sortSettings}
              />
              
          </div>

          
        </div>
        
      </main>
    </div>
    
  );
}

export default App;