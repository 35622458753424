import './Components.css'
import { RoundedIcon } from './media/RoundedIcon';
import { SolanaIcon } from './media/SolanaIcon'
import { TokenIcon } from './media/TokenIcon'
import { SlipageModes } from './utils'
import { TbCirclePercentage, TbSwitchHorizontal } from "react-icons/tb";
import { CiBag1 } from "react-icons/ci";
import { useContext } from 'react';
import { ModalContextProvider } from './ModalProvider';
import { RiInformationLine } from 'react-icons/ri';
import { FaCross, FaCrosshairs, FaInfo, FaPercent } from 'react-icons/fa';
import { PiPercentThin } from 'react-icons/pi';
import { TfiInfo } from 'react-icons/tfi';

export const GeneralInput = ({ formProps, option, label, isError, className, ...other }) => {
  return (
    <div className={`amountInput ${isError? 'error' : '' } ${className}`} {...other}>
      <div className='main'>
        <label>{label}</label>
        <input type="text" {...formProps}></input>
      </div>
      <div className='additional'>
        {option}
      </div>
    </div>
  )
}

export const InputAmount = ({ formProps, label, isTokenMode, toggleMode, isError, ...other }) => {
  return (
    <GeneralInput 
      {...other}
      isError={isError}  
      label={`${label} (${isTokenMode? 'TOKEN' : 'SOL'})`}
      formProps={formProps} 
      option={
        <div className='d-flex justify-content-end align-items-center h-100 w-100 gap-2'>
          <RoundedIcon 
            
            unclickable
            inner={
              isTokenMode? <TokenIcon width={30} height={30} /> : <SolanaIcon width={30} height={30}/>
            }
          />
          <RoundedIcon 
            inner={
              <TbSwitchHorizontal size={25}/>
            }  
            onClick={toggleMode}
          />
          
              
          
        </div>
      }
    >

    </GeneralInput>
  )
}

export const Marks = ({ mask }) => {
  const { info } = useContext(ModalContextProvider)
  const devsold = (parseInt(mask) & 0x1) === 1
  const bundledetected = false // (parseInt(mask >> 1) & 0x1) === 1

  return (
    <div>
    {
      bundledetected && <FaCrosshairs onClick={() => info(
        <div className='d-flex-column'>
          <div>Bundle buy detected. Suspicious activity. Very high rug chance</div>
          
        </div>,
        'Bundle detected'
      )} color="var(--primary-red)"/>
    }
    {
      devsold && <FaCross onClick={() => info(
        <div className='d-flex-column'>
          <div>Dev sold more than 90% tokens</div>
          
        </div>,
        'Dev sold'
      )} color="var(--primary-red)"/>
    }
    </div>
  )
    
}

const renderSlipageINfo = () => {
  return (
    <div className='m-3'>
      Transaction confirmations are not instant. At a moment when a transaction is confirmed
      there might be other people that outrun you and bought before you. It changes the price and you might
      end up paying more for the same amount of tokens. <b>Slippage</b> restricts the price. 
      It can be defined in two ways

      <br/><br/>
      <b>Percentage (%):</b> if you pay X, then the maximum amount that you permit to spend is (1 + 0.01 * slippage) * X

      <br/><br/>
      <b>Absolute (SOL):</b> allows to specify the total amount of SOLANA for how much 
      other traders can buy before you. 
    </div>
  )
}

export const SlipageInput = ({ formProps, mode, setMode, label, isError, ...other }) => {
  const { info } = useContext(ModalContextProvider)

  return (
    <GeneralInput 
      {...other}
      isError={isError}
      label={`${label} ${mode === SlipageModes.percentage? '(%)' : '(SOL)'}`}
      formProps={formProps} 
      option={
        <div className='d-flex justify-content-end align-items-center h-100 w-100 gap-2'>
          
          <RoundedIcon
            
            onClick={() => setMode(SlipageModes.percentage === mode? SlipageModes.absolute : SlipageModes.percentage)} 
            inner={
              SlipageModes.percentage === mode? <PiPercentThin size={25} /> : <CiBag1 size={25}/>
            }  
          />

          <RoundedIcon
            
            onClick={() => info(renderSlipageINfo)} 
            inner={
              <TfiInfo size={25} />
            }  
          />
        </div>
      }
    >

    </GeneralInput>
  )
}