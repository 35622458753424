
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import './App.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { DataProvider } from './DataProvider';
import { SolanaProvider } from './SolanaProvider';
import { ToastContainer } from 'react-toastify';
import { SettingsProvider } from './SettingsProvider';
import { ModalProvider } from './ModalProvider'
import { register } from 'timeago.js';

export const PREFIX = '/api'

window.Buffer = buffer.Buffer

if (!String.prototype.format) {
  String.prototype.format = function() {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function(match, number) { 
      return typeof args[number] != 'undefined'
        ? args[number]
        : match
      ;
    });
  };
}


const localeFunc = (number, index, totalSec) => {
  return [
    ['now', 'right now'],
    ['%s sec', 'in %s seconds'],
    ['1 min', 'in 1 minute'],
    ['%s min', 'in %s minutes'],
    ['1 hour', 'in 1 hour'],
    ['%s hours', 'in %s hours'],
    ['1 day', 'in 1 day'],
    ['%s days', 'in %s days'],
    ['1 week', 'in 1 week'],
    ['%s weeks', 'in %s weeks'],
    ['1 month', 'in 1 month'],
    ['%s months', 'in %s months'],
    ['1 year', 'in 1 year'],
    ['%s years', 'in %s years']
  ][index];
};
register('en_US', localeFunc);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SolanaProvider>
      <SettingsProvider>
        <DataProvider>
          <ModalProvider>
            <App/>
          
            <ToastContainer autoClose={3000}/>
          </ModalProvider>
        </DataProvider>
      </SettingsProvider>
    </SolanaProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
