import { useState, useEffect, useRef, createContext, useContext } from 'react'
import './App.css';
import { BN, BorshCoder, Program, web3 } from '@project-serum/anchor';
import { ComputeBudgetProgram, PublicKey, SYSVAR_RENT_PUBKEY, SystemProgram, TransactionInstruction } from '@solana/web3.js';
import idl from './api.json'
import { getMaxSolAmount, getMaxSolAmountFromSol, getMaxSolAmountFromTokens, makeVirtualBuy } from './utils';
import * as bs58 from "bs58"
import { isMobile } from 'react-device-detect';

const MAGIC_ID_1 = new PublicKey("4wTV1YmiEkRvAtNtsSGPtUrqRYQMe5SKy2uB4Jjaxnjf")
const MAGIC_ID_2 = new PublicKey("CebN5WGQ4jvEPvsVU4EoHEpgzq1VV7AbicfhtW4xC9iM")
const TOKEN_PROGRAM_ID = new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA');
const ASSOCIATED_TOKEN_PROGRAM_ID = new PublicKey('ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL')
const PUMP_PROGRAM_ID = new PublicKey("6EF8rrecthR5Dkzon8Nwu78hRvfCKubJ14M5uBEwF6P")
const param1 = new PublicKey("Ce6TQqeHC9p8KetsN6JsjHK7UTZk7nasjjnr7XxXp9F1")
const param2 = new PublicKey("6EF8rrecthR5Dkzon8Nwu78hRvfCKubJ14M5uBEwF6P")

const tipsell = {
  units: 110000,
  computeUnitPrice: 140000,
}
const tipbuy = {
  units: 110000,
  computeUnitPrice: 140000,
}

const TRADER_API_TIP_WALLET = "HWEoBxYs7ssKuudEjzjmpfJVX7Dvi7wescFsVx2L5yoY"
const TRADER_API_TIP = 300000

function CreateTraderAPITipInstruction(
  senderAddress,
  tipAmount
) {
  const tipAddress = new PublicKey(TRADER_API_TIP_WALLET)

return SystemProgram.transfer({
    fromPubkey: senderAddress,
    toPubkey: tipAddress,
    lamports: tipAmount,
})
}



function CreateFeeTipperInstruction(
  senderAddress,
  tipAmount
) {
  const tipAddress = new PublicKey('FZhszTpQ8CtYaVzqZcjM52EvGACfjUprWFdjnWm2s8dt')
  return SystemProgram.transfer({
    fromPubkey: senderAddress,
    toPubkey: tipAddress,
    lamports: (new BN(tipAmount)).mul(new BN(process.env.REACT_APP_FEE)).div(new BN(100)).toString(),
  })
}

function findAssociatedTokenAddress(walletAddress, tokenMintAddress) {

  
  return PublicKey.findProgramAddressSync([
      walletAddress.toBuffer(),
      TOKEN_PROGRAM_ID.toBuffer(),
      tokenMintAddress.toBuffer(),
    ],
    ASSOCIATED_TOKEN_PROGRAM_ID
  )[0];
}

export const SolanaProviderContext = createContext({
  account: '',
  provider: null,
  error: '',
  specialSell: null
})

function buildAssociatedTokenAccountInstruction(
  payer,
  associatedToken,
  owner,
  mint,
  instructionData,
  programId = TOKEN_PROGRAM_ID,
  associatedTokenProgramId = ASSOCIATED_TOKEN_PROGRAM_ID
) {
  const keys = [
    { pubkey: payer, isSigner: true, isWritable: true },
    { pubkey: associatedToken, isSigner: false, isWritable: true },
    { pubkey: owner, isSigner: false, isWritable: false },
    { pubkey: mint, isSigner: false, isWritable: false },
    { pubkey: SystemProgram.programId, isSigner: false, isWritable: false },
    { pubkey: programId, isSigner: false, isWritable: false },
  ];

  return new TransactionInstruction({
      keys,
      programId: associatedTokenProgramId,
      data: instructionData,
  });
}

function createAssociatedTokenAccountInstruction(
  payer,
  associatedToken,
  owner,
  mint,
  programId = TOKEN_PROGRAM_ID,
  associatedTokenProgramId = ASSOCIATED_TOKEN_PROGRAM_ID
) {
  return buildAssociatedTokenAccountInstruction(
    payer,
    associatedToken,
    owner,
    mint,
    Buffer.alloc(0),
    programId,
    associatedTokenProgramId
  );
}

const coder = new BorshCoder(idl)
export const getVirtualReserves = async (bonding_curve, connection) => {
  const res = await connection.getAccountInfo(new PublicKey(bonding_curve), 'processed')
  
  let obj = coder.accounts.decode('BondingCurve', res.data)
  for (let key in obj) {
    obj[key] = obj[key].toString()
  }
  
  const y = new BN(obj.virtualTokenReserves)
  const x = new BN(obj.virtualSolReserves)

  return { x, y }
}
  
export function SolanaProvider({ children }) {
  const [account, setAccount] = useState('')
  const [error, setError] = useState('')
  const provider = useRef(null);
  const pumpProgram = useRef(null)
  
  function getProvider() {
    if ('phantom' in window) {
      const provider = window.phantom?.solana;

      if (provider?.isPhantom) {
        return provider;
      }
    }

    return null
  }

  const getLatestBlockHash = async () => {
    let res = await fetch(`${process.env.REACT_APP_USER_INFO_URL}/lastblockhash`)
    let json = await res.json()
    return json.lastblockhash
  }

  const sendTx = async (signedTx) => {
    const body = JSON.stringify({
      serializedTransaction: bs58.encode(signedTx),
      retries: 5
    })
  
    const res = await fetch('https://client-api-2-74b1891ee9f9.herokuapp.com/send-transaction', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body
    })
    const json = await res.json()
    return json.signature
  }
  
  const sell = async (mint, bondingCurve, amountTokens) => {
    const bondingCurvePubKey = new PublicKey(bondingCurve)
    const mintPubKey = new PublicKey(mint)
    const accountPubKey = new PublicKey(account)

    const associatedBoundingCurve = findAssociatedTokenAddress(bondingCurvePubKey, mintPubKey) 
    const associatedTokenAccount = findAssociatedTokenAddress(accountPubKey, mintPubKey);
    
    //logger.info(`Spend ${want_spend_sol / 1e9} SOL = ${want_tokens / 1e6} ${token_name} (MAX: ${max_cost_sol /1e9} SOL).\nhttps://pump.fun/${mint}`)

    //console.log(amountTokens)
    
    const currentAmount = new BN(amountTokens)
    const maxCostSol = new BN(10000000) // calculate dynamically

    const ixn = await pumpProgram.current.methods
      .sell(currentAmount, maxCostSol)
      .accounts(
        {
          "global": MAGIC_ID_1,
          "feeRecipient": MAGIC_ID_2,
          "mint": mintPubKey,
          "bondingCurve": bondingCurvePubKey,
          "associatedBondingCurve": associatedBoundingCurve,
          "associatedUser": associatedTokenAccount,
          "user": accountPubKey,
          "systemProgram": SystemProgram.programId,
          "associatedTokenProgram": ASSOCIATED_TOKEN_PROGRAM_ID,
          "tokenProgram": TOKEN_PROGRAM_ID,
          "param11": param1,
          "param12": param2
        }
      ).instruction()

    let instructions = [
      ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 500000 }),
      ComputeBudgetProgram.setComputeUnitLimit({ units: 110000 }),
      CreateTraderAPITipInstruction(accountPubKey, TRADER_API_TIP),
      //CreateFeeTipperInstruction(accountPubKey),
      ixn
    ]; 

    let lastblockhash = await getLatestBlockHash()

    let mes = new web3.TransactionMessage({
      payerKey: accountPubKey,
      recentBlockhash: lastblockhash,
      instructions,
    }).compileToV0Message()

    const tx = new web3.VersionedTransaction(mes)
    
    const signedTx = await provider.current.signTransaction(tx)
    
  
    //const serialized = Buffer.from(signedTx.serialize()).toString('hex')
    
    const res = await sendTx(signedTx.serialize())
    return res
  }

  const buy = async (
    mint, bondingCurve, 
    currentSolBalance, settings
  ) => {
    /*
    console.log(mint.toString())
    console.log(bondingCurve.toString())
    console.log(currentSolBalance.toString())
    console.log(settings)
    */
    const bondingCurvePubKey = new PublicKey(bondingCurve)
    const mintPubKey = new PublicKey(mint)
    const accountPubKey = new PublicKey(account)
    
    /*
      const res = await getVirtualReserves(bondingCurvePubKey)
      console.log(res.x.toString())
      console.log(res.y.toString())
      console.log(new BN(currentSolBalance).add(new BN(30000000000)).toString())
    */

    const associatedBoundingCurve = findAssociatedTokenAddress(bondingCurvePubKey, mintPubKey)
    const associatedTokenAccount = findAssociatedTokenAddress(accountPubKey, mintPubKey);
    
    const nextState = makeVirtualBuy(new BN(currentSolBalance))

    const { isTokenAmount, amount, slipage, slipageMode } = settings

    let wantTokens = new BN(0)
    let maxCostSol = new BN(0)
    let wantSpendSol = new BN(0)

    if (isTokenAmount) {
      

      [wantTokens, maxCostSol, wantSpendSol] = getMaxSolAmountFromTokens(
        new BN(amount), 
        new BN(slipage),
        slipageMode,
        nextState
      )
    } else {
      //console.log('IS NOT TOKEN')
      

      [wantTokens, maxCostSol, wantSpendSol] = getMaxSolAmountFromSol(
        new BN(amount), 
        new BN(slipage),
        slipageMode,
        nextState
      )
    }
    
    console.log(`${wantTokens / 1e6} max cost: ${maxCostSol / 1e9} wantspend: ${wantSpendSol / 1e9}`)
    const ixn = await pumpProgram.current.methods
      .buy(wantTokens, maxCostSol)
      .accounts({
        "global": MAGIC_ID_1,
        "feeRecipient": MAGIC_ID_2,
        "mint": mintPubKey,
        "bondingCurve": bondingCurvePubKey,
        "associatedBondingCurve": associatedBoundingCurve,
        "associatedUser": associatedTokenAccount,
        "user": accountPubKey,
        "systemProgram": SystemProgram.programId,
        "tokenProgram": TOKEN_PROGRAM_ID ,
        "rent": SYSVAR_RENT_PUBKEY, 
        "param11": param1,
        "param12": param2
      })
      .instruction()
    
    // check transaction based on createaassociatedtokenaccount
    
    let instructions = [
      //ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 500000 }),
      //ComputeBudgetProgram.setComputeUnitLimit({ units: 110000 }),
      createAssociatedTokenAccountInstruction(
        accountPubKey, 
        associatedTokenAccount, 
        accountPubKey, 
        mintPubKey
      ),
      CreateTraderAPITipInstruction(accountPubKey, TRADER_API_TIP),
      CreateFeeTipperInstruction(accountPubKey, wantSpendSol.toString()),
      ixn,
      
    ]; 


    let lastblockhash = await getLatestBlockHash()

    let mes = new web3.TransactionMessage({
      payerKey: accountPubKey,
      recentBlockhash: lastblockhash,
      instructions,
    }).compileToV0Message()
    
    const tx = new web3.VersionedTransaction(mes)
    // signandsend
    // await provider.current.signAndSendTransaction(signedTx)
    const signedTx = await provider.current.signTransaction(tx)
    
    //const serialized = Buffer.from(signedTx.serialize()).toString('hex')
    
    
    const res = await sendTx(signedTx.serialize())
    
    return res
  }

  useEffect(() => {    
    provider.current = getProvider()
    
    if (!provider.current) {
      setError('No provider found, please install Phantom Wallet')
      return 
    }

    provider.current.on('accountChanged', (publicKey) => {
      //console.log('changed account')
      //console.log(publicKey)
      if (publicKey) {
        setAccount(publicKey.toBase58())
        setError('')
      }
    });

    provider.current
    .connect()
    .then(async (resp) => {

      setAccount(resp.publicKey.toBase58())
      setError('')
      
      pumpProgram.current = new Program(idl, PUMP_PROGRAM_ID, provider)
    })
    .catch(() => setError('Unable to connect'))
    
    return () => provider.current.disconnect()
  }, [])

  const specialSell = async (mint, tokenAmount, bondingCurve) => {
    //console.log(account)
    const res = await fetch(`${process.env.REACT_APP_BOT_URL}/sell?mint=${mint}&tokenAmount=${tokenAmount}&account=${account}&bondingCurve=${bondingCurve}`)
    const json = await res.json()
    if (json.error)
      throw new Error(json.error)
    return json
  }

  if (isMobile) {
    return <>{children}</>
  }
  
  return (
    <SolanaProviderContext.Provider value={{ 
      account, 
      getVirtualReserves: async (bondingCurve) => {
        //return getVirtualReserves(bondingCurve, connection.current)
      }, 
      error, 
      buy, 
      sell, 
      specialSell, 
      provider: provider.current 
    }}>
      {children}
    </SolanaProviderContext.Provider>
  )
}
