import { BN } from "@project-serum/anchor"
import { getVirtualReserves } from "./SolanaProvider"

const PUMP_FEE = 5
const PUMP_FEE_DENOMINATOR = 1000

const r = new BN(100)
const rd = new BN(100)
  
/**
 * x - solana
 * y - token
 */

// input amount
export function calc_dx(dy, x, y) {
  return x.mul(dy).div(y.sub(dy))
}

// output amount
export function calc_dy(dx, x, y) {
  return y.mul(dx).div(x.add(dx))
}

export const uiTokenStatus = {
  new: 'new',
  up: 'up',
  down: 'down'
}

export function makeVirtualBuy(amountSol, state = null) {
  let obj = state
  if (!obj) {
    obj = { // INIT TOKEN 
      virtualTokenReserves: '1073000000000000',
      virtualSolReserves: '30000000000',
    }
  }

  const y = new BN(obj.virtualTokenReserves)
  const x = new BN(obj.virtualSolReserves)
  
  const new_y = y.sub(calc_dy(amountSol, x, y))
  const new_x = x.add(amountSol)

  return {
    virtualSolReserves: new_x.toString(),
    virtualTokenReserves: new_y.toString()
  }
}

export function short(data, letters = 4) {
  return `${data.slice(0, letters)}...${data.slice(-1 * letters)}`
}

export function getMaxSolAmountFromTokens(want_tokens, slipage, slipageMode, state = null) {
  let obj = state
  if (!obj) {
    obj = { // INIT TOKEN 
      virtualTokenReserves: '1073000000000000',
      virtualSolReserves: '30000000000',
    }
  }


  let max_cost_sol = new BN(0)
  const y = new BN(obj.virtualTokenReserves)
  const x = new BN(obj.virtualSolReserves)
  let wantSpendSol = calc_dx(want_tokens, x, y) 

  if (slipageMode === SlipageModes.absolute) {

    const { virtualSolReserves, virtualTokenReserves } = makeVirtualBuy(slipage, obj)
    const new_y = new BN(virtualTokenReserves)
    const new_x = new BN(virtualSolReserves)
    max_cost_sol = calc_dx(want_tokens, new_x, new_y)

  } else if (slipageMode === SlipageModes.percentage) {
    //const wantSpendSol = calc_dx(want_tokens, x, y)  //0.007924928
    max_cost_sol = wantSpendSol.mul(slipage.add(new BN(100))).div(new BN(100))
  }
  
  return [want_tokens, max_cost_sol, wantSpendSol]
}

export function getMaxSolAmountFromSol(wantSpendSol, slipage, slipageMode, state = null) {
  let obj = state
  if (!obj) {
    obj = { // INIT TOKEN 
      virtualTokenReserves: '1073000000000000',
      virtualSolReserves: '30000000000',
    }
  }
  
  const y = new BN(obj.virtualTokenReserves)
  const x = new BN(obj.virtualSolReserves)

  const want_tokens = calc_dy(wantSpendSol, x, y)
  return getMaxSolAmountFromTokens(want_tokens, slipage, slipageMode, state)
}

export function calculate_sol_balance(realSolReserveNow, tokenBalance) {  
  const { virtualSolReserves, virtualTokenReserves} = makeVirtualBuy(realSolReserveNow)
  const res = calc_dy(tokenBalance, new BN(virtualTokenReserves), new BN(virtualSolReserves))
  return res.mul(new BN(PUMP_FEE_DENOMINATOR - PUMP_FEE)).div(new BN(PUMP_FEE_DENOMINATOR))
}


export const SlipageModes = {
  'percentage': 1,
  'absolute': 2
}
