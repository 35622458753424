import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { SettingsProviderContext } from "./SettingsProvider";
import { InputAmount, SlipageInput } from './components';
import { SlipageModes } from "./utils";
import { FiInfo } from "react-icons/fi";
import { ModalContextProvider } from "./ModalProvider";
import NumericLabel from 'react-pretty-numbers'
import { FaTelegram, FaTwitter } from "react-icons/fa";
import { DataProviderContext } from "./DataProvider";
import { toast } from "react-toastify";

const solanaAmountUiRegexp = /^\d+(\.\d{1,9})?$/g
const tokenAMountPattern = /^\d+(\.\d{1,6})?$/g
const percentagePattern = /^\d{1,2}$/g

export function ControlPanel({ style, className, ...other }) {
  const { unlockReward, getUnlockReward  } = useContext(DataProviderContext)
  const { settings, toggleBot, updateSettings } = useContext(SettingsProviderContext)
  const { isTokenAmount, slipageMode, amount, slipage, isBotEnabled, isBotPaused } = settings
  const [ update, setUpdate] = useState(true)
  const [unlockRewardLoading, setUnlockRewardLoading] = useState(false)
  const { info } = useContext(ModalContextProvider) 

  // It's bad in real programming, but it is web 
  const rerender = () => {
    setUpdate(v => !v)
  }

  const { 
    register, handleSubmit, reset,
    setValue, getValues,
    formState: { errors, isValid, isDirty } 
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      isTokenAmount,
      slipageMode,
      slipage: slipageMode !== SlipageModes.percentage? (slipage / 1e9).toFixed(2) : slipage,
      amount: (isTokenAmount? amount / 1e6 : amount / 1e9).toFixed(1)
    }
  });

  const onSubmit = data => {

    updateSettings({
      isTokenAmount: data.isTokenAmount,
      slipageMode: data.slipageMode,
      amount: parseInt((parseFloat(data.amount) * (data.isTokenAmount? 1e6 : 1e9))),
      slipage: parseInt((parseFloat((data.slipageMode === SlipageModes.percentage? data.slipage : data.slipage * 1e9)) ))
    })

    reset(data)

    toast.success('New settings applied!')
  }
  
  const buyRenderSettingsInfo = () => {
    return (
      <div>
        It is a panel that allows you to adjust buying settings like amount of tokens to buy, slippage and etc
        <br/>
      </div>
    )
  }

  const renderDescription = ({ slipageMode, isTokenAmount, amount, slipage}) => {
    let text = ''

    const amountTag = (amount, isToken) =>
      <NumericLabel params={{ justification: 'C', cssClass: ["numeric"], precision: isToken? 0 : 2, shortFormat: false }}>
        {(amount / (isToken?  1e6 : 1e9)).toFixed(isToken? 0 : 2)}
      </NumericLabel>
    

    if (slipageMode === SlipageModes.percentage) {
      if (isTokenAmount) {
        text = <div>Buy <b>{amountTag(amount, true)} TOKEN</b> with a <b>{slipage}%</b> slipage</div>
      } else {
        text = <div>Buy <b>{amountTag(amount, false)} SOLANA</b> with <b>{slipage}%</b> slipage</div>
      }
    } else {
      if (isTokenAmount) {
        text = <div>Buy <b>{amountTag(amount, true)} of TOKEN</b> and tolerate <b>{amountTag(slipage, false)} SOL</b> buy before you</div>
      } else {
        text = <div>Buy <b>{amountTag(amount, false)} of SOLANA</b> and tolerate <b>{amountTag(slipage, false)} SOL</b> buy before you</div>
      }
    }
    return (
      <div style={{ paddingTop: '10px', textAlign: 'center', fontStyle: 'italic' }}>
        {text}
      </div>
    )
  }
  
  return (
    <>
      <div className={`container ${className}`} style={{ ...style, borderTopLeftRadius: '0px' }}>
        <div className="container-label" >
          <div>Buy settings</div>
          <FiInfo className="sociallink" size={20} onClick={() => info(buyRenderSettingsInfo)} />
        </div>

        <div style={{ position: 'absolute', right: 0, top: -25, display: 'flex', gap: '10px' }}>
          {/* <FaTwitter size={20} className="sociallink" onClick={() => window.open('https://buywif.fun', '_blank')}/>*/}
          Join us: { <FaTelegram size={20} className="sociallink" onClick={() => window.open('https://t.me/buywiffun', '_blank')}/>}
        </div>

        <form onSubmit={handleSubmit(onSubmit)} >
          <div style={{ display: ' flex', paddingBottom: '10px', justifyContent: 'space-between' }}>
            <InputAmount 
              style={{ marginRight: '20px' }}
              isError={errors['amount'] != undefined}
              isTokenMode={getValues().isTokenAmount}
              toggleMode={() => {
                setValue('isTokenAmount', !getValues().isTokenAmount, { shouldDirty: true, shouldValidate: true })
                rerender()
              }} 
              label="amount" 
              formProps={{
                ...register("amount", 
                { 
                  required: true, 
                  pattern: isTokenAmount? tokenAMountPattern : solanaAmountUiRegexp 
                }),
                type: "text"
              }}
            />
            <SlipageInput 
              isError={errors['slipage'] != undefined}
              mode={getValues().slipageMode}
              setMode={(mode) => {
                setValue('slipageMode', mode, { shouldDirty: true, shouldValidate: true })
                rerender()
              }}
              label="slipage" 
              formProps={{
                ...register("slipage", { 
                  required: true, 
                  pattern: slipageMode === SlipageModes.percentage? percentagePattern : solanaAmountUiRegexp }
                ), 
                type: "text"
              }} 
            />
          </div>
            
          {renderDescription(settings)}

          <div className="d-flex mt-2 gap-2"> 
          {
            (Object.values(errors).length !== 0 || !isDirty) ||
            <button
              style={{ width: '100%' }}
              className={`buyBtn p-1`}
              type="submit" 
            >
              Save new settings
            </button>
          }
          
          {
            unlockReward > 0 &&
             <button
                className={`buyBtn p-1 ${unlockRewardLoading && 'disabled'}`}
                onClick={async (e) => { 
                  setUnlockRewardLoading(true)
                  e.preventDefault(); 
                  await getUnlockReward() 
                  setUnlockRewardLoading(false)
                }}
              >
                {unlockRewardLoading? 'Processing...' : <>Unlock  <NumericLabel>{unlockReward / 1e9}</NumericLabel> SOL 🔓</>} 
              </button>
          }

          {
            
            <button
              className={`buyBtn p-1`}   
              onClick={(e) => {
                e.preventDefault()
                info(<div className="p-3 d-flex justify-content-center align-items-center">Not ready yet, we are working very hard to bring live into it</div>, 'Trading bot 🤖')
              }}         
            >
             Trading bot 🤖
          </button>
          
          }
          </div>
          
          
        </form>
        
        {
          isBotEnabled &&
          <button className="buyBtn mt-2" onClick={() => toggleBot()}>
            {isBotPaused ? "BUY: ⛔ paused" : "BUY: ✅ running"}
          </button>
        }

        
        
      </div>
    </>
  )
}