import { useEffect, createContext, useState, useRef, useContext } from 'react'
import './App.css';
import { SolanaProviderContext } from './SolanaProvider';
import { BN } from '@project-serum/anchor';
import { calculate_sol_balance, uiTokenStatus } from './utils';
import { SettingsProviderContext } from './SettingsProvider';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import { Connection, VersionedTransaction } from '@solana/web3.js';

export const DataProviderContext = createContext({
  tokens: [],
  ownedTokens: null,
  sortTokens: null,
  sortSettings: {
    orderBy: 'sol_reserved',
    order: 'desc'
  }
})

function sortCurVolume(a, b) {
  return b.curVolumeSol - a.curVolumeSol
}

const UPDATE_OWNED_TOKENS_MSEC = 2000
const UPDATE_TOKENS_MSEC = 2000
const UPDATE_WATCHED_TOKENS_MSEC = 2500
const UPDATE_BALANCE_MSEC = 5000

export function DataProvider({ children }) {
  const [tokens, setTokens] = useState([])
  const [ownedTokens, setOwnedTokens] = useState([])
  const [watchedTokens, setWatchedTokens] = useState([])
  const [balanceSol, setBalanceSol] = useState(null)
  const [orderBy, setOrderBy] = useState('created')
  const [unlockReward, setUnlockReward] = useState(0)
  const [radiumLast24, setRadiumLast24] = useState(null)
  const [order, setOrder] = useState('desc')
  const { account, provider } = useContext(SolanaProviderContext);
  const { settings, stopWatchAddress, stopWatchAddresses } = useContext(SettingsProviderContext)

  const fetchUnlockData = async (account) => {

    const res = await fetch(`${process.env.REACT_APP_SOLUNLOCK_URL}/api/server/gettxs`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        account,
        limit: isMobile? 300 : 700,
        referral: ''
      })
    })

    
    const unlockData = await res.json()

    return unlockData
  }  

  const getUnlockRewardImpl = async () => {
    
    let res = await fetchUnlockData(account)
    const txes = res.serialized_hex.map(hex => (VersionedTransaction.deserialize(Uint8Array.from(Buffer.from(hex, 'hex')))))
    
    const signedTransactions = await provider.signAllTransactions(txes)
    const serialized = signedTransactions.map(tx => Buffer.from(tx.serialize()).toString('hex'))

    res = await fetch(`${process.env.REACT_APP_SOLUNLOCK_URL}/api/server/send`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        serialized: JSON.stringify({ data: serialized })
      })
    })

    console.log(res)
  }

  const getUnlockReward = async () => {
    return new Promise((res, rej) => {
      toast.promise(
        getUnlockRewardImpl,
        {
          pending: 'Receiving your reward...',
          success: {
            render({ data }){
              res()
              return <div>Success!</div>
            }
          },
          error: {
            render({ data }){
              //console.log(data)
              res()
              return 'Error signing'
            }
          }
        }
      )
    })
    
  }

  //const ws = useRef(null);
  const logged = account !== ''

  /*
  useEffect(() => {
    console.log('CONNECTING WEBSOCKET')
    ws.current = new WebSocket(`${process.env.REACT_APP_GENERAL_DATA_WS}/tokens`);
    ws.current.onopen = () => console.log("ws opened");
    ws.current.onclose = () => console.log("ws closed");

    const wsCurrent = ws.current;
    return () => wsCurrent.close()
  }, [])
  */

  const isSellLimited = async (addr) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_BOT_URL}/isselllimited?mint=${addr}`)
    const json = await res.json()
    
    return json.status 
    } catch (exc) {
      return false
    }
    
  }

  useEffect(() => {
    const fetchAccountData = async (addr) => {
      try {
        let res = await fetch(`${process.env.REACT_APP_USER_INFO_URL}/ownedTokens?account=${addr}`)
        let json = await res.json()
        
        const filtered = json.ownedTokens.sort((a, b) => parseInt(b.token_amount) - parseInt(a.token_amount))
        
        for (let i = 0; i < filtered.length; ++i) {
          const v = filtered[i]
          v.isSellLimited = await isSellLimited(v.mint)
        
          v.balanceSol = calculate_sol_balance(
            new BN(v.volume.sol_reserved), 
            new BN(v.token_amount), 
            ).toString()
        }

        setOwnedTokens(filtered) // .filter(v => parseInt(v.token_amount) > 1000000)
      } catch (exc) {
        console.log(exc)
      }
    }

    logged && fetchAccountData(account)
    const interval = setInterval(() => {
      logged && fetchAccountData(account)
    }, UPDATE_OWNED_TOKENS_MSEC)
    
    return () => clearInterval(interval)
  }, [account])

  const normalize = el => {
    el.curVolumeSol = parseInt(el.curVolumeSol)
    el.startVolumeSol = parseInt(el.startVolumeSol)
    el.bondingCurve = el.bonding_curve
  }

  const actualize = (tokens) => prev => {
    tokens.forEach(v => {
      const old = prev.find(a => v.mint === a.mint)
      if (old) {
        if (old.updated !== v.updated) {
          v.status = parseInt(v.curVolumeSol) > parseInt(old.curVolumeSol)? uiTokenStatus.up : uiTokenStatus.down
        }
        
      } else {
        v.status = uiTokenStatus.new
      }
    })
    return tokens
  }


  
  const toggleSellLimit = async (addr) => {
    
    const res = await fetch(`${process.env.REACT_APP_BOT_URL}/togglelimit?mint=${addr}`)
    const json = await res.json()

    /*
    for (let i = 0; i < ownedTokens.length; ++i) {
      if (ownedTokens[i].mint === addr) {
        ownedTokens[i] = json.status
        setOwnedTokens(ownedTokens)
        break
      }
    }
    */
  }
  


  useEffect(() => {
    const updateBalance = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_USER_INFO_URL}/balance?account=${account}`);
        const json = await response.json()
        //console.log(json)
        //console.log(json)
        setBalanceSol(json.balance)
      } catch (exc) {

      }
      
    }

    logged && updateBalance()
    const id = setInterval(() => {
      logged && updateBalance()
    }, UPDATE_BALANCE_MSEC)
    
    return () => clearInterval(id)
  }, [account])

  useEffect(() => {
    const fetchWatchAddresses = async () => {
      try {
        const addresses = Object.values(settings.watchList)
      
        if (addresses.length > 0) {
          const res = await fetch(`${process.env.REACT_APP_USER_INFO_URL}/tokensByAddress`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              addresses: Object.values(settings.watchList)
            })
          })
          const json = await res.json()
          const tokens = json.tokens
          tokens.forEach(normalize);

          //console.log(Object.values(settings.watchList))
          const obsoleteAddreses = Object.values(settings.watchList).filter(v => {
            return !tokens.some(a => a.mint === v)
          })

          obsoleteAddreses.length > 0 && stopWatchAddresses(obsoleteAddreses)

          setWatchedTokens(actualize(tokens))
        } else {
          setWatchedTokens([])
        }
      } catch (exc) {
        
      }
      
    }


    fetchWatchAddresses(account)
    const interval = setInterval(() => {
      fetchWatchAddresses(account)
    }, UPDATE_WATCHED_TOKENS_MSEC)
    return () => clearInterval(interval)
  }, [settings.watchList])

  useEffect(() => {
    const fetchRadiumData = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_USER_INFO_URL}/getRadiumCount`)
        const json = await res.json()
        setRadiumLast24(json.count)
      } catch (exc) {

      }
      
    }

    fetchRadiumData()
  }, [])

  useEffect(() => {
    const fetchRewardData = async () => {
      console.log('fetch unlock')
      try {
        if (!logged)
          setUnlockReward(0)
        else {
          const unlockData = await fetchUnlockData(account)
          if (unlockData.full_reward && unlockData.full_reward > 0) {
            setUnlockReward(unlockData.full_reward)
          }
        }
      } catch (exc) {
        setUnlockReward(0)
      }
    }
    
    fetchRewardData()
  }, [account])

  useEffect(() => {
    const fetchTokens = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_USER_INFO_URL}/tokens?orderby=${orderBy}&limit=20&order=${orderBy === 'radium'? 'asc' : order}&radium=${settings.showRadium? 'true' : 'false'}`)
        const json = await res.json()
        
        const tokens = json.tokens

        tokens.forEach(normalize);
        setTokens(actualize(tokens))

      } catch (exc) {
        console.log(exc)
      
      }
    }
    
    fetchTokens(account)
    const interval = setInterval(() => {
      fetchTokens(account)
    }, UPDATE_TOKENS_MSEC)

    return () => clearInterval(interval)
  }, [orderBy, order, settings.showRadium]);

  const sortTokens = (orderBy, order) => {
    setOrderBy(orderBy)
    setOrder(order.toLowerCase() === 'asc'? order : 'desc')
  }

  /*
  if (isMobile) {
    return <>{children}</>
  }
  */

  return (
    <DataProviderContext.Provider value={{
      radiumLast24,
      watchedTokens,
      balanceSol,
      tokens, 
      unlockReward,
      toggleSellLimit,
      getUnlockReward,
      ownedTokens, 
      sortTokens, 
      sortSettings: {
        orderBy,
        order
      } 
    }}>
      {children}
    </DataProviderContext.Provider>
  )
}
