import { Icon } from "./RoundedIcon"

export const TokenIcon = ({ width, height, ...params }) => {
  return (
    
        <svg fill="#000000" height={height || "30px" } width={width || "30px"} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  
          viewBox="0 0 296.473 296.473" >
            <g>
              <path d="M148.237,0C66.368,0,0.001,66.367,0.001,148.236s66.367,148.236,148.236,148.236c81.867,0,148.234-66.367,148.234-148.236
                S230.104,0,148.237,0z M222.075,38.495l-9.446,13.962c-12.569-8.467-26.897-14.519-42.319-17.506l3.314-16.525
                C190.994,21.785,207.417,28.588,222.075,38.495z M155.903,214.025v17.211h-14v-17.124c-10-1.099-19.384-4.93-26.44-11.495
                c-7.058-6.564-10.286-16.215-10.054-28.941l0.358-0.439h24.726c0,8,1.779,13.311,5.336,16.664
                c3.558,3.355,8.082,5.035,13.578,5.035c5.263,0,9.297-1.4,12.102-4.207c2.805-2.805,4.208-6.577,4.208-11.32
                c0-4.686-1.345-8.502-4.034-11.451c-2.689-2.95-7.303-5.696-13.838-8.242c-12.841-4.686-22.371-9.904-28.588-15.66
                c-6.218-5.755-9.326-13.867-9.326-24.336c0-9.718,2.873-17.684,9.089-23.902c6.217-6.217,14.881-9.876,24.881-10.976V66.236h14
                v18.778c10,1.505,18.31,5.756,24.123,12.754c5.813,6.999,8.728,15.976,8.613,27.138l-0.193,0.33h-24.727
                c0-7-1.403-11.984-4.208-15.686c-2.806-3.701-6.637-5.501-11.495-5.501c-4.976,0-8.676,1.457-11.105,4.32
                c-2.43,2.863-3.643,6.681-3.643,11.423c0,4.511,1.286,8.176,3.86,10.981c2.573,2.806,7.302,5.571,14.186,8.289
                c12.782,5.032,22.254,10.412,28.413,16.139c6.16,5.726,9.24,13.738,9.24,24.033c0,10.064-3.361,18.105-9.549,24.12
                C175.227,209.371,166.903,212.927,155.903,214.025z M122.85,18.426l3.192,16.549c-15.417,3.004-29.739,9.069-42.3,17.55
                l-9.343-14.03C89.057,28.588,105.479,21.785,122.85,18.426z M38.496,74.397l13.962,9.446
                c-8.467,12.569-14.518,26.896-17.505,42.318l-16.526-3.314C21.787,105.478,28.59,89.055,38.496,74.397z M38.495,222.073
                c-9.907-14.658-16.709-31.081-20.068-48.451l16.549-3.192c3.004,15.417,9.069,29.74,17.55,42.301L38.495,222.073z M74.397,257.976
                l9.446-13.962c12.569,8.468,26.897,14.52,42.32,17.507l-3.314,16.526C105.478,274.686,89.054,267.884,74.397,257.976z
                M173.623,278.047l-3.192-16.549c15.418-3.004,29.74-9.069,42.302-17.551l9.343,14.03
                C207.417,267.885,190.993,274.688,173.623,278.047z M257.978,222.074l-13.962-9.446c8.467-12.569,14.518-26.898,17.505-42.319
                l16.525,3.314C274.686,190.993,267.884,207.416,257.978,222.074z M261.497,126.041c-3.004-15.417-9.069-29.74-17.551-42.302
                l14.03-9.343c9.907,14.658,16.71,31.082,20.069,48.452L261.497,126.041z"/>
            </g>
            </svg>
    
  )
}