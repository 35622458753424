
import React, { createContext, useState } from "react";
import styles from "./Modal.module.css";
import { RiCloseLine } from "react-icons/ri";
import { FaCross, FaCrosshairs, FaQuestion } from "react-icons/fa";
import { UNKNWON_RISK_MASK } from "./riskmask";

export const ModalContextProvider = createContext({
  show: null
})

export const ModalProvider = ({ children }) => {
  const [infoText, setInfoText] = useState(null)
  const [label, setLabel] = useState(null)

  const info = (text, label) => {
    setLabel(label || 'Info')
    setInfoText(text)
  }

  const close = () => {
    setInfoText(null)
    setLabel(null)
  }

  const marksInfo = () => {
    info(
      <div className="d-flex-column h-100 align-items-center justify-content-center gap-1">
        These are all bad signs. If you see some of them it should make you very suspicous about the token
        <div className="mt-3">
          <FaCross color={'var(--primary-red)'}/> - developer sold more than 90% of his tokens
        </div>
        
        <div>
          <FaCrosshairs color={'var(--primary-red)'} /> - bundle buy detected. Suspicious activity. Very high rug chance
        </div>
        
        <div>
          <FaQuestion color={'var(--primary-red)'} /> - suspicous activity that is usually not encountered in good tokens
        </div>
      </div>, 
      'What does this marks mean?'
    )
  }

  const _riskWarningInfo = (risks, riskmask) => {
    
    if (riskmask & UNKNWON_RISK_MASK) {
      return (<div>Sorry, we could not analyze this mint, so we can't provide any information about it. Buy it at your own risk.</div>)  
    }

    return (
      <div className="">
        {risks.map((desc) => <div className="mt-1">{desc}<br/></div>)}
      </div>
    )
  }

  const riskWarningInfo = (risks, riskmask) => {
    info(_riskWarningInfo(risks, riskmask), riskmask & UNKNWON_RISK_MASK? 'Unknown token' : 'Why warning?')
  }

  return (
    <ModalContextProvider.Provider value={{ info, close, marksInfo, riskWarningInfo }}>
      {children}
      {
        infoText && 
        <>
          <div className={styles.darkBG} onClick={() => close(false)} />
          <div className={styles.centered}>
            <div className={`${styles.modal}`}>
              <div>
                <h5 className={styles.heading}>{label}</h5>
              </div>
              <button className={styles.closeBtn} onClick={() => close()}>
                <RiCloseLine size={25}/>
              </button>
              <div className={styles.modalContent}>
                {infoText}
              </div>
              
            </div>
          </div>
        </>
      }
    </ModalContextProvider.Provider>
    
  );
};
