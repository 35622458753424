import { useContext } from "react";
import { SettingsProviderContext } from "./SettingsProvider";
import { SolanaProviderContext } from "./SolanaProvider";
import { toast } from "react-toastify";
import { short } from "./utils";
import NumericLabel from 'react-pretty-numbers'
import { ModalContextProvider } from "./ModalProvider";
import { FiInfo } from "react-icons/fi";
import { isBrowser, isMobile } from "react-device-detect";
import { Marks } from "./components";
import { UNKNWON_RISK_MASK, decodeMask } from "./riskmask";
import { GrStatusUnknown } from "react-icons/gr";
import { IoIosWarning } from "react-icons/io";
import { format } from "timeago.js";
import { FaLink, FaTelegram, FaTwitter } from "react-icons/fa";
import { DataProviderContext } from "./DataProvider";

export function BoughtTokens({ ownedTokens, className, style }) {
  const { sell, specialSell } = useContext(SolanaProviderContext);
  const { settings: { isBotEnabled } } = useContext(SettingsProviderContext)
  const { info, marksInfo, riskWarningInfo } = useContext(ModalContextProvider)
  const { toggleSellLimit } = useContext(DataProviderContext) 

  const onBotSell = ({ mint, tokenAmount, bondingCurve }) => {
    toast.promise(
      specialSell(mint, tokenAmount, bondingCurve),
      {
        pending: 'Signing transaction...',
        success: {
          render({ data }){
            console.log(data)
            return <a>TX SENT</a>
          }
        },
        error: {
          render({ data }){
            console.log(data)

            return (data && data.message)? data.message : 'Error while sending'
          }
        }
      }
    )
  }

  const onSell = ({ bondingCurve, mint, tokenAmount }) => {
    toast.promise(
      sell(mint, bondingCurve, tokenAmount),
      {
        pending: 'Signing transaction...',
        success: {
          render({ data }){
            return <a target='_blank' href={`https://solscan.io/tx/${data}`}>txid: {short(data)}</a>
          }
        },
        error: {
          render({ data }){
            console.log(data)
            return 'Error signing'
          }
        }
      }
    )
  }

  const renderYourTokensInfo = () => {
    return (
      <div >
        It is a list of tokens that are already bought. Column <b>Balance SOL</b> is constantly 
        updated and reflects an amount of solana that can be withdrawn if sold right away 
      </div>
    )
  }

  if (ownedTokens.length == 0)
    return (
      <div className='container text-muted ' style={{ ...style, textAlign: 'center', backgroundColor: 'transparent', boxShadow: 'none', border: 'none', marginBottom: '0px' }} >
        You don't have tokens yet
      </div>
    )
  
  const renderRow = ({ mint, token_amount, bonding_curve, isSellLimited, balanceSol, volume: { symbol: token_name, riskmask_dynamic, website, twitter, telegram, logo, curVolumeSol, riskmask, riskmask_data, radium } }) => {
    const radiumProgress = radium? 100 : Math.min(((parseInt(curVolumeSol)) / (85 * 1e7)), 100)
    let showWarning = false
    let risks = []
    if (riskmask > 0) {
      const res = decodeMask(riskmask, riskmask_data)
      risks = res.risks
      showWarning = res.showWarning
    }

    return (
      <tr key={mint} style={{ position: 'relative' }}>
        <td className="logotd text-start" style={{ paddingLeft: '0px' }}>
          <div className="d-flex align-content-center" style={{ width: '120px' }}>
            <img className="logo" src={logo? logo : '/question.jpeg'} loading="lazy" width="20" height="20" style={{ marginRight: '5px', borderRadius: '50%'}} />
            <a target='_blank' style={{ textDecoration: 'none' }} href={`https://pump.fun/${mint}`}>
              {token_name.length > 10? `${token_name.slice(0, 10)}...` : token_name}
            </a>
          </div>
        </td>
        <td style={{ width: isMobile? '75px' : '80px' }} >
          <div  className="d-flex justify-content-start gap-1 align-items-center">
          { website && <FaLink className="sociallink" onClick={() => window.open(website, '_blank')}/>}
          { twitter && <FaTwitter className="sociallink" onClick={() => window.open(twitter, '_blank')}/>}
          { telegram && <FaTelegram className="sociallink" onClick={() => window.open(telegram, '_blank')}/>}

          </div>
        </td>
        <td>
          <Marks mask={riskmask_dynamic}></Marks>
        </td>
        <td className="text-center">
          <NumericLabel params={{ justification: 'C', precision: 1, shortFormat: true }}>
            {(token_amount / 1e6).toFixed(0)}
          </NumericLabel>
        </td>
        <td className="text-center">
          <NumericLabel>
            {(balanceSol / 1e9).toFixed(3)}
          </NumericLabel>
        </td>
        <td >
          <div className="d-flex p-1 gap-2" style={{ height: '23px'}}>
          { 
            !isBotEnabled &&
            <button 
              className='buyBtn w-100' 
              onClick={() => onSell({ mint, bondingCurve: bonding_curve, tokenAmount: token_amount })}
            >
              SELL
            </button>
          } 
          

          
          {
            isBotEnabled && 
            <button 
              className={`buyBtn`} 
              style={{ width: '100%', backgroundColor: 'var(--primary-red)' }} 
              onClick={() => onBotSell({ mint, tokenAmount: token_amount, bondingCurve: bonding_curve })}
            >
              SELL
            </button>
          }
          { 
            isBotEnabled &&
            <button 
              className='buyBtn' 
              style={{ width: '30px' }}
              onClick={() => {toggleSellLimit(mint)}}
            >
              {!isSellLimited? '✅' : '⛔'}
              
            </button>
          } 
          {
            
            <button 
              className={`buyBtn`} 
              style={{ visibility: showWarning? 'visible' : 'hidden', width: '30px' }} 
              onClick={() => riskWarningInfo(risks, riskmask)}
            >
              {riskmask & UNKNWON_RISK_MASK? <GrStatusUnknown size={16} color="white" /> : <IoIosWarning size={16} color="#f7f797" />}
              
            </button> 
          } 
          </div>
        
          
        </td>
        <td style={{ paddingRight: '0px', width: isMobile? '80px' : '100px', textAlign: 'right' }}>
          {isBrowser && <div className="progress" style={{ left: `${radiumProgress - 100}%`}}></div>}
          <div >{radium? `${format(radium)} 🏆` : `${radiumProgress.toFixed(2)}%`}</div>
        </td>
      </tr>
    )
  }

  return (
    <div className={`container w-100 ${className}`} style={{ ...style, borderTopLeftRadius: '0px' }}>
      <div className="container-label">
        <div>Your tokens</div>
        <FiInfo className="sociallink" size={20} onClick={() => info(renderYourTokensInfo)} />
      </div>
      <div style={{  padding: (ownedTokens.length > 10)? '15px 0px 35px 0px' : '', overflow: 'visible' }}>
        <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
          <div style={{ padding: '15px 0px 35px 0px', overflow: 'hidden' }}>
          
            <table className="w-100 table"> 
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col text-left"></th>
                  <th scope="col"><a href='#' style={{ textDecoration: 'none', position: 'relative' }} onClick={marksInfo}>Marks</a></th>
                  <th scope="col">Balance</th>
                  <th scope="col">Balance Sol</th>
                  <th scope="col"></th>
                  <th scope="col" style={{ textAlign: 'right' }}>Raydium</th>
                  
                </tr>
              </thead>
              <tbody className="table-body">
              {ownedTokens.map(renderRow)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}