export const RISKS  = [
  {
    id: 0,
    text: "Unknown mint",
  },
  {
    id: 1,
    text: "Sol transferred from {0} {1} before token creation",
  },
  {
    id: 2,
    text: "Dev created {0} tokens before",
  },
  {
    id: 3,
    text: "Dev sold more than 50% in {0} tokens before",
  },
  {
    id: 4,
    text: "No associated suspicious dev addresses found",
  },
  {
    id: 5,
    text: "Unknown SOL sources {0}",
  },
  {
    id: 6,
    text: "SOL Transferred from suspicious address {0} {1} before token creation",
  }
];

export const RISKS_DYNAMIC  = [
  {
    id: 64,
    text: "Dev sold > 90% tokens",
  },
  {
    id: 65,
    text: "Bundle buy detected. Suspicious activity. Very high rug chance",
  }
];

export const UNKNWON_RISK_MASK = 1 << 0
export const NO_RISKS_DYNAMIC = 0

const printAgo = (dt) => {
  const s = parseInt(parseInt(dt) / 1000)
  const m = parseInt(s / 60)
  const h = parseInt(m / 60)

  if (h > 0)
    return `${h} hours ago`

  if (m > 0)
    return `${m} minutes ago`

  if (s > 0)
    return `${s} seconds ago`
}

export const decodeMask = (mask, data) => {
  let parsedObj = {}
  
  try {
    let parsedData = JSON.parse(data)
    parsedObj = parsedData.reduce((acc, v) => ({ ...acc, [v.id]: v.data }) , {})
  } catch  {

  }

  let showWarning = true
  let tmp = mask
  const risks = []
  let i = 0

  while ((tmp >> i) !== 0 && i < RISKS.length) {
    const bit = 0x1 & tmp >> i
    if (bit === 1) {
      if (parsedObj[i]) {
        parsedObj[i].forEach(params => {
          if (i == 2 && params[0] === '0') {
            showWarning = false
          }
          
          if (i === 6 && params[1]) {
            params[1] = printAgo(params[1])
          }

          if (i === 1 && params[1]) {
            params[1] = printAgo(params[1])
          }
          risks.push(RISKS[i].text.format(...params))
        });
      } else {
        risks.push(RISKS[i].text)
      }
      
    }
      

    ++i
  }
  
  return ({
    risks, showWarning
  })
}