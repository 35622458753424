import { createContext, useEffect, useState } from 'react'
import { SlipageModes } from './utils'
import { toast } from 'react-toastify'

const KEY = 'snipefun5'
const settingsFromStorage = localStorage.getItem(KEY)
console.log(settingsFromStorage)
const settingsDefault = settingsFromStorage? JSON.parse(settingsFromStorage) : {
  amount: 100000000,
  slipage: 40,
  slipageMode: SlipageModes.percentage,
  isTokenAmount: false,
  isBotEnabled: false,
  isBotPaused: true,
  showRadium: true,
  watchList: {}
}

export const SettingsProviderContext = createContext({ ...settingsDefault })

export function SettingsProvider({ children }) {
  const [settings, setSettings] = useState(settingsDefault)

  const stopWatchAddresses = (addresses) => {
    let newObj = { ...settings.watchList }
    
    addresses.forEach(addr => {
      if (!settings.watchList[addr])
        return
      
      delete newObj[addr]
    })
    
    const newSettings = { watchList: { ...newObj } }

    updateSettings(newSettings)
  }

  const toggleShowRadium = () => {
   
    updateSettings({
      ...settings,
      showRadium: !settings.showRadium
    })
  }

  const watchAddress = (addr) => {
    if (settings.watchList[addr])
      return

    if (Object.values(settings.watchList).length == 4) {
      toast.info('LIMIT REACHED')
      return
    }

    const newObj = { ...settings.watchList, [addr]: addr }
    const newSettings = { watchList: { ...newObj } }

    updateSettings(newSettings)
  }

  const stopWatchAddress = (addr) => {
    stopWatchAddresses([addr])
  }

  const updateSettings = (updated, dontSave = false) => {
    setSettings(v => {
      const newSettings = { ...v, ...updated }
      !dontSave && localStorage.setItem(KEY, JSON.stringify(newSettings))
      return newSettings
    })
  }

  const toggleBot = async () => {
    
    const res = await fetch(`${process.env.REACT_APP_BOT_URL}/togglebuy`)
    const json = await res.json()
    updateSettings({ isBotPaused: json.status === "0" }, true)
  }

  useEffect(() => {
    const check = async () => {
      try {
        if (!process.env.REACT_APP_BOT_URL)
          throw 'NO bot'

        await toggleBot()
        await toggleBot()
        updateSettings({ isBotEnabled: true }, true)
      } catch (exc) {
        updateSettings({ isBotEnabled: false }, true)
      }
    }
    
    check()
  }, [])

  return (
    <SettingsProviderContext.Provider value={{ 
      settings, 
      toggleShowRadium,
      updateSettings, 
      toggleBot, 
      watchAddress, 
      stopWatchAddress,
      stopWatchAddresses 
    }} >
      {children}
    </SettingsProviderContext.Provider>
  )
}
